<script setup lang="ts">
import type { Badge } from "@/types";

defineProps<{
	bestDeal?: boolean;
	mostPopular?: boolean;
	badge?: Badge;
}>();

const { t } = useT();
</script>

<template>
	<ACornerBadge
		v-if="badge?.labelText"
		variant="custom"
		:backgroundColor="badge?.labelBackground"
		:textColor="badge?.labelTextColor"
		class="badge-top"
	>
		{{ badge.labelText }}</ACornerBadge
	>
	<ACornerBadge v-else-if="bestDeal" variant="warning" class="badge-top"> {{ t("Best deal") }} </ACornerBadge>
	<ACornerBadge v-else-if="mostPopular" variant="info" class="badge-top">
		{{ t("most popular") }}
	</ACornerBadge>
</template>

<style scoped lang="scss">
.badge-top {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	border-radius: 0 0 8px 0;

	&:deep(span) {
		padding: 8px 12px;
		display: inline-flex;
	}
}
</style>
